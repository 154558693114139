import axios from 'axios';
import ApiService from '../API';
import { TokenStorageService, UserStorageService } from '../Storage';

const AuthURL = process.env.REACT_APP_AUTH_BASE_URL;

const AuthService = {
  async login(email, password) {
    const emailBase64 = window.btoa(email);
    const passwordBase64 = window.btoa(password);

    try {
      const { data: data_1 } = await axios
        .post(
          `${AuthURL}/v1/login`,
          {
            grant_type: 'client_credentials',
            profile: process.env.REACT_APP_AUTH_PROFILE
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Basic ${`${emailBase64}:${passwordBase64}`}`,
            },
          });
      TokenStorageService.saveToken(data_1.access_token);
      TokenStorageService.saveRefreshToken(data_1.refresh_token);
      UserStorageService.saveUser(data_1.username)
      ApiService.setHeader();
    } catch (error) {
      throw error;
    }
  },

  async logout() {
    try {
      return axios.delete(`${AuthURL}/v1/revoke`, {
        data: { refresh_token: TokenStorageService.getRefreshToken() },
      });
    } catch (error) {
      return error
    }
  },

  async newAcessToken() {
    const refreshToken = TokenStorageService.getRefreshToken();
    try {
      return axios.patch(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/renew`, {
          refresh_token: refreshToken,
        })
    } catch (error) {
      return error
    }
  },
};

export default AuthService;