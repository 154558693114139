import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';

// colors
const primaryMain = '#039be5'; // Lightblue - 600
const secondaryMain = '#bbe54b'; // Orange - 800

const black = '#343a40';
const white = '#fff';
const light = '#e0e0e0';

const background = '#f5f5f5';

// border
const borderWidth = 2;
const borderColor = 'rgba(0, 0, 0, 0.13)';

// breakpoints
const xl = 1920;
const lg = 1280;
const md = 960;
const sm = 600;
const xs = 0;

// spacing
const spacing = 8;

const theme = createMuiTheme({
  palette: {
    primary: { main: primaryMain },
    secondary: {
      main: secondaryMain,
    },
    common: {
      white,
      light,
      black,
    },
    background: {
      default: background,
    },
    spacing,
  },
  breakpoints: {
    values: {
      xl,
      lg,
      md,
      sm,
      xs,
    },
  },
  border: {
    borderColor,
    borderWidth,
  },
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MUIDataTable: {
      paper: {
        paddingTop: `${spacing * 2}px !important`,
        paddingLeft: `${spacing * 4}px !important`,
        paddingRight: `${spacing * 4}px !important`,
      },
    },
    MUIDataTableHeadCell: {
      root: {
        textAlign: 'center',
      },
    },
    MUIDataTableBodyCell: {
      root: {
        textAlign: 'center',
      },
    },
    MuiAlert: {
      root: {
        alignItems: 'center',
      },
    },
  },
});

export default responsiveFontSizes(theme);
