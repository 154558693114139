import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';
import ApiService from '../../services/API';
import { TokenStorageService, UserStorageService } from '../../services/Storage';
import AuthService from '../../services/Session';

export const SessionContext = createContext();

export const SessionProvider = (props) => {
  const { children } = props;
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userAuthenticated, setUserAuthenticated] = useState();
  const [loading, setLoading] = useState(true);

  function getUserAuthenticated() {
    const user = UserStorageService.getUser();
    setUserAuthenticated(user);
  }

  async function signIn(email, password) {
    const response = await AuthService.login(email, password);
    setIsAuthenticated(true);
    return response;
  }

  function signOut() {
    AuthService.logout().then(()=>{
      setIsAuthenticated(false);
      TokenStorageService.removeToken();
      UserStorageService.removeUser();
      ApiService.removeHeader();
    }).catch(()=>{
      setIsAuthenticated(false);
      TokenStorageService.removeToken();
      UserStorageService.removeUser();
      ApiService.removeHeader();
    })
  }

  useEffect(() => {
    try {
      const token = TokenStorageService.getRefreshToken();
      if (token) {
        const { exp } = jwtDecode(token);
        if (exp > new Date().getTime() / 1000) {
          ApiService.setHeader();
          getUserAuthenticated();
          setIsAuthenticated(true);
        }
      }
    } catch (error) {
      
    }
    setLoading(false);
  }, []);

  return (
    <SessionContext.Provider
      value={{ loading, isAuthenticated, userAuthenticated, signIn, signOut }}
    >
      {children}
    </SessionContext.Provider>
  );
};

SessionProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export default () => useContext(SessionContext);