import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../../contexts/Session';

function CustomRoute({ component: Component, ...rest }) {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={() => {
        if (!isAuthenticated) {
          return <Component {...rest} />;
        }
        return (
          <Redirect
            to={{
              pathname: '/app/online',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}

CustomRoute.propTypes = {
  component: PropTypes.object.isRequired,
};

export default CustomRoute;
